"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { AnalyticsEventName } from "@/types";
import track from "@/utils/track";
import TextInput from "../Form/Inputs/TextInput/TextInput";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setSavedPath,
  setSavedFollow,
} from "../../redux/slices/savedProgressSlice";
import {
  CURRENT_USER_QUERY,
  GET_CLUSTERS_QUERY,
  GET_CLUSTER_QUERY,
  SIGN_IN_MUTATION,
  UPDATE_CLUSTER_MUTATION,
} from "../../apollo/queries";
import formatAPIErrors from "../../utils/formatAPIErrors";
import Separator from "../Separator/Separator";
import SubmitButton from "../SubmitButton/SubmitButton";
import ContinueWithGoogleButton from "../ContinueWithGoogleButton/ContinueWithGoogleButton";

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Please enter your email."),
  password: Yup.string()
    // NOTE: Temporarily removing validation to accept and handle both pins and passwords
    // .min(8, "Password must be at least eight characters long.")
    // .max(16, "Password can't be longer than 16 characters.")
    // .matches(
    //   /(?=.*[a-z])/,
    //   "Password should include at least one lowercase letter.",
    // )
    // .matches(
    //   /(?=.*[A-Z])/,
    //   "Password should include at least one uppercase letter.",
    // )
    .required("Please enter a password."),
});

const INPUTS = [
  {
    name: "email",
    type: "email",
    placeholder: "Your email address*",
  },
  {
    name: "password",
    type: "password",
    placeholder: "Your password*",
  },
];

const Signin = () => {
  const router = useRouter();
  const [signInMutation] = useMutation(SIGN_IN_MUTATION);
  const dispatch = useAppDispatch();
  const { savedPath, savedFollow } = useAppSelector(
    (state) => state.savedProgress,
  );

  const [updateCluster] = useMutation(UPDATE_CLUSTER_MUTATION, {
    refetchQueries: [
      GET_CLUSTERS_QUERY,
      {
        query: GET_CLUSTER_QUERY,
        variables: {
          input: {
            id: savedFollow,
          },
        },
      },
    ],
  });

  return (
    <div className="flex flex-col w-full md:max-w-[640px]">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={SigninSchema}
        onSubmit={async (
          { email, password },
          { setSubmitting, resetForm, setErrors },
        ) => {
          if (password.match(/^[0-9]{4}$/)) {
            resetForm();
            router.push("/request-reset-password?migratePin=true");
          } else {
            await signInMutation({
              variables: {
                input: {
                  email: email.toLowerCase(),
                  password,
                  miTool: process.env.NEXT_PUBLIC_ADMIN_TOOL_ENABLED === "true",
                },
              },
              onCompleted: async ({ signIn: { user } }) => {
                if (user) {
                  resetForm();

                  await track(AnalyticsEventName.LOGIN);

                  if (savedFollow) {
                    // TODO: might need to do same mutations as in FollowButton
                    await updateCluster({
                      variables: {
                        input: {
                          id: savedFollow,
                          data: {
                            follow: true,
                          },
                        },
                      },
                    });

                    dispatch(setSavedFollow(null));
                  }

                  // redirect to user feed
                  if (savedPath) {
                    router.push(savedPath);
                    dispatch(setSavedPath(null));
                  } else {
                    router.push("/sparks");
                  }
                }

                // TODO: might need to use apollo equivalent to finally block - will we get here if error?
                setSubmitting(false);
              },
              onError: (error) => {
                const formattedErrors = formatAPIErrors(error);
                setErrors(formattedErrors);
              },
              update: (
                cache,
                {
                  data: {
                    signIn: { user },
                  },
                },
              ) => {
                // update apollo cache
                cache.writeQuery({
                  query: CURRENT_USER_QUERY,
                  data: {
                    currentUser: user,
                  },
                });
              },
            });
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form
            className="flex flex-col text-lg w-full p-0"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-y-4 mb-8">
              {INPUTS.map(({ name, type, placeholder }) => (
                <TextInput
                  key={name}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                />
              ))}
            </div>
            <SubmitButton
              className="bg-blue"
              text="Sign In"
              submittingText="Signing in"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
      <Separator>OR</Separator>
      <ContinueWithGoogleButton />
      <span className="text-center text-sm mt-4">
        Don&apos;t have an account? &nbsp;
        <Link className="text-red" href="/sign-up">
          Sign Up
        </Link>
      </span>
      <span className="text-center text-sm mt-2">
        Forgot your password? &nbsp;
        <Link className="text-blue" href="/request-reset-password">
          Reset Password
        </Link>
      </span>
    </div>
  );
};

export default Signin;
